<template>
  <div v-if="!resultComponent" class="spin">
    <a-spin />
  </div>
  <div v-else>
    <keep-alive>
      <component :is="resultComponent"></component>
    </keep-alive>
  </div>
</template>

<script>
import _ from "lodash";
import { defineAsyncComponent, shallowRef } from "vue";
export default {
  name: "CampaignProgress",
  computed: {
    pageCount: function () {
      return this.$store.getters.pageCount;
    },
    campaignData: function () {
      return this.$store.getters.campaignData;
    },
  },
  data() {
    return {
      resultComponent: null,
    };
  },
  metaInfo() {
    return {
      title: this.campaignData.meta_title,
      meta: [
        {
          name: "title",
          content: this.campaignData.meta_title,
          vmid: "title",
        },
        {
          name: "description",
          content: this.campaignData.meta_description,
          vmid: "description",
        },
        {
          property: "og:title",
          content: this.campaignData.meta_title,
          vmid: "og:title",
        },
        {
          property: "og:description",
          content: this.campaignData.meta_description,
          vmid: "og:description",
        },
        {
          property: "og:image",
          content: this.campaignData.meta_image,
          vmid: "og:image",
        },
      ],
    };
  },
  watch: {
    pageCount: function (count) {
      if (!_.isEmpty(this.campaignData)) {
        this.resultComponent = shallowRef(
          defineAsyncComponent(() =>
            import(
              `@/view/frontend/campaign/${this.campaignData.game_slug}/${this.campaignData.theme_slug}/Page${count}.vue`
            )
          )
        );
      }
    },
  },
  created() {
    if (!_.isEmpty(this.campaignData)) {
      this.resultComponent = shallowRef(
        defineAsyncComponent(() =>
          import(
            `@/view/frontend/campaign/${this.campaignData.game_slug}/${this.campaignData.theme_slug}/Page${this.pageCount}.vue`
          )
        )
      );
      this.addCustomCss();
    } else {
      this.goBack();
    }
  },
  methods: {
    goBack() {
      this.$router.replace({ path: `/campaign/${this.$route.params.slug}` });
    },
    addCustomCss() {
      const { custom_css = "" } = this.campaignData;
      if (custom_css) {
        let style = document.createElement("style");
        style.innerHTML = custom_css;
        document.head.append(style);
      }
    },
  },
};
</script>